export default function SocialLinks() {
    return (
        <div className="banner-social-links">
            <div className="social-links">
                <a href="https://www.instagram.com/elev8exchange_/">
                    <span>in</span><span>Instagram</span>
                </a>
                <a href="https://www.linkedin.com/company/elev8-groupuk/about/">
                    <span>li</span><span>LinkedIn</span>
                </a>
                {/*<a href="https://vanderpump.tech" target="_blank" rel="noreferrer">*/}
                {/*    <span>MB</span><span>Made by</span>*/}
                {/*</a>*/}
            </div>
        </div>
    )
}